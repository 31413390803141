.prn-table-element {
    margin: 10px;
    height: 76px;
    background-color: white;
    cursor: pointer;
}

.element-cell {
    float: left;
    /* padding: 0px 15px */
}

.prn-table-element-checkbox {
    margin-left: 34px;
}

.prn-table-element-prNumber {
    color: #001e50;
    font-family: vw-text, Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 24px;
    width: 40px;
}

.prn-table-element-title {
    width: 248px;
    min-width: 248px;
    color: #000000;
    font-family: vw-text, Arial, Helvetica, sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: 40px;
}

.prn-table-element-modelName {
    width: 100px;
    text-align: center;
}

.same-element {
    color: #001e50;
    font-family: vw-text, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 60px;
}

.prn-table-element-modeYear {
    width: 60px;
}

.prn-table-element-category {
    min-width: 150px;
    text-align: center;
}

.prn-table-element-status {
    text-align: center;
    width: 150px;
}

.prn-not-translated {
    color: #d1d1d1;
}

.conversion-flag {
    height: auto;
    width: 100px;
    border-radius: 4px;
    background-color: #c2cacf;
    color: #3c484d;
    font-family: vw-text, Arial, Helvetica, sans-serif;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
}
