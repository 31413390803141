.contact-us-component {
    height: 157px;
    width: 100%;
    /* transform: rotate(180deg); */
    background: radial-gradient(circle, #00437a 0%, #001e50 100%);
}

.contact-us-component .Enqao {
    color: #ffffff;
    border-color: #ffffff;
}

.contact-us-component .content-section {
    text-align: center;
    padding-top: 1%;
}

.contact-us-content {
    color: #ffffff;
    font-family: "vw-head";
    font-size: 48px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 60px;
    text-align: center;
}

.contact-us-button {
    display: inline-flex;
    text-align: center;
    background: #ffffff;
    align-items: center;
    min-height: 44px;
    margin: 0;
    padding: 0 40px;
    border: 2px solid;
    border-radius: 22px;
    outline: none;
    color: #000000;
}

.contact-us-button:hover{
    background: #f3f3f3;
}
