.preview-title {
    height: 78px;
    width: 774px;
    color: #000000;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 78px;
    text-align: start;
    font-family: "vw-head";
}

.prntitle {
    height: 78px;
    color: #000000;
    font-family: "vw-head";
    font-weight: bold;
    letter-spacing: 0;
    line-height: 78px;
}

.preview-data-section {
    width: 100%;
}

.floating-text-label {
    height: 24px;
    width: auto;
    color: #001e50;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 36px;
}

.header {
    height: 20px;
    width: 956px;
    color: #000000;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 36px;
    text-align: start;
}

.header-title {
    height: 27px;
    width: 957px;
    color: #000000;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 12px;
    text-align: start;
}

.column-3 {
    height: 250px;
    width: 716px;
    color: #090a0a;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 300px;
    text-align: start;
}

.column-4 {
    height: 50px;
    width: 716px;
    color: #090a0a;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 300px;
    text-align: start;
}

.column-5 {
    height: 50px;
    width: 716px;
    color: #090a0a;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 300px;
    text-align: start;
}

.align-text {
    text-align: start;
    margin-left: 270px;
}

.column {
    height: 20px;
    width: 201px;
    color: #000000;
    letter-spacing: 0;
    line-height: 20px;
    margin-left: 60px;
    margin-top: 32px;
    text-align: start;
}

.column-2-line {
    height: 2px;
    width: 957px;
    background-color: #000000;
}

.line-margin {
    margin-top: 16px;
    width: auto;
}

.align-button {
    text-align: end;
    padding: 10px;
}
.preview-section {
    min-width: 1000px;
}

.column-1-line {
    height: 1px;
    max-width: 16%;
    background-color: #000000;
    min-width: 80px;
}

.column-1-line-3 {
    height: 1px;
    background-color: #000000;
    margin-left: 21%;
    /* width: 75%; */
}

@media screen and (max-width: 820px) {
    .column-1-line-3 {
        /* margin-left: 27%; */
        width: 75%;
    }
}

@media screen and (max-width: 768px) {
    .description-form-content {
        /* padding-left: 60px !important; */
        padding-left: 5% !important;
    }

    .content-heading {
        min-width: 175px;
    }
}

@media screen and (max-width: 520px) {
    .column-1-line-3 {
        /* margin-left: 42%; */
        width: 65%;
    }
}

.align-image {
    margin-top: 240px;
    text-align: start;
    display: flex;
    flex-direction: column;
    height: 25%;
    max-height: 25%;
    overflow-y: scroll;
}

.align-image-text {
    text-align: start;
    margin-left: 24px;
}

.align-button-margin {
    text-align: start;
    padding: 10px;
}

.align-button-top-margin {
    margin-top: 10%;
    float: right;
}

.button-primary-default-main-br {
    height: 44px;
    width: 400px;
}

.description-align {
    text-align: start;
    margin-top: 32px;
    margin-bottom: 32px;
    width: auto !important;
}

.button-for-adding-comments {
    padding-top: 40px;
    display: inline-flex;
}

.content-heading {
    width: 112px;
    /* min-width: 175px; */
}

.text-for-comments {
    margin-left: 32px;
    width: 258px;
    color: #001e50;
    font-family: "vw-text";
    letter-spacing: 0;
    line-height: 20px;
}

.description-form-content {
    /* padding-left: 75px; */
    padding-left: 5.5% !important;
}

.column-1-line {
    height: 1px;
    width: 171px;
    background-color: #000000;
}

.image-upload-card-preview {
    width: 100%;
    display: flex;
}

.uploaded-image-preview {
    height: 222px;
    width: 323px;
}

.image-card-preview {
    width: 50%;
    display: inline-block;
    margin-bottom: 2%;
    margin-top: 2%;
    padding-left: 5%;
}

.title-image-preview {
    padding-bottom: 4px;
    text-align: center;
    padding-right: 15px;
    width: 250px;
    color: #000000;
    font-family: "vw-text";
    letter-spacing: 0;
    line-height: 20px;
    cursor: pointer;
}

.column-1-line-preview {
    height: 2px;
    max-width: 16%;
    background-color: white;
    min-width: 80px;
}

.margin_top_image {
    margin-top: 1%;
}

.image-border-style {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
}

.image_frame {
    width: 250px;
    height: 200px;
    border: 1px solid white;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
}

.pdf_button_style {
    margin-top: 2%;
}

.image-title-in-preview {
    text-align: center !important;
    display: block !important;
    color: #001e50;
    font-family: vw-head, Arial, Helvetica, sans-serif;
    letter-spacing: 0;
    line-height: 20px;
}
.disabled-anchor {
    cursor: none;
    pointer-events: none;
}
