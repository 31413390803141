.overview-page-introduction {
    color: #000000;
    font-family: vw-head, Arial, Helvetica, sans-serif;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 78px;
}

.overview-page-search-input {
    width: 315px;
    /* float: left; */
}

#media-library-section .dcyxok {
    float: left;
    width: 316px;
}

.overview-page-search-input:focus {
    outline-style: none;
}
.filter-prn-section {
    margin-top: 10px;
}
.overview-page-table-list {
    background-color: #eaecec;
}

.sort-filter-buttons-section {
    float: right;
    padding-bottom: 30px;
    padding-right: 61px;
    padding-top: 30px;
}
