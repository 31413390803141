.outer {
  display: inline;
  margin-top: 1.5%;
  position: relative;
}

.sort-input {
  float: left;
  margin-right: 2%;
}

.sort-by {
  color: #001e50;
  font-family: vw-head, Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
}

/* svg:not(:root) { */
.sort-by svg {
  /* position: absolute;  */
  top: 1%;
}

.KXuKk.KXuKk.KXuKk:hover {
  /* color: #0040C5; */
  color: #ffffff;
}

#sort-block .KXuKk {
  width: 140%;
}

#sort-block .chkdVI:checked {
  color: #ffffff;
}

#sort-block .StyledFocusWrapper-sc-1nt8p46 {
  color: #ffffff;
}

.up-arrow {
  display: inline-block;
  position: relative;
  /* border      : 1px solid #777777; */
  text-decoration: none;
  border-radius: 2px;
  padding: 20px;
  /* margin-top  : 50px; */
}

.up-arrow:before {
  content: "";
  display: block;
  position: absolute;
  left: 80%;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #001d53;
}

.up-arrow:after {
  content: "";
  display: block;
  position: absolute;
  left: 80%;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #001d53;
}

#sort-block {
  height: 177.5px;
  width: 188px;
  background-color: #001d53;
  border-radius: 8px;
  display: none;
  margin-top: 12%;
  position: absolute;
  z-index: 1;
  right: 0;
}

.unordered-list {
  list-style: none;
  margin-left: 12px;
  padding: 0;
}
/* 
  label {
    color        : #ffffff;
    margin-bottom: 7%;
  } */

input[type="radio"] + label::before {
  background: #001d53;
}

#sort-funtionality-prnumbers label:hover,
.wrapper:hover,
.form-inline:hover {
  color: #ffffff;
}

input[type="radio"]:checked + label:hover::before {
  background: #ffffff !important;
}

input[type="radio"]:checked + label::before {
  background: #ffffff;
  box-shadow: inset 0 0 0 4px #001d53;
  border-color: #ffffff;
}

input[type="radio"] + label {
  display: table;
  color: #ffffff;
  font-family: vw-text, Arial, Helvetica, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
}
