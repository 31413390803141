.dropdown-label-select {
    color: #001e50;
    font-family: "vw-text";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    float: left;
    width: 135px;
    cursor: pointer;
    position: absolute;
}

.dropdown-label-select-name {
    width: 100px;
}

#filter-prn .css-26l3qy-menu {
    width: 90%;
}

#category-fiter .css-26l3qy-menu {
    width: 120%;
}

.filterCategory .css-26l3qy-menu {
    width: 120% !important;
}
