.imprint {
    margin-left: 15%;
    margin-right: 15%;
}

.imprint-title {
    font-family: "vw-head";
    font-size: 68px;
    margin-left: 25%;
}

.imprint-headline {
    font-weight: bold;
}

.imprint-copyright {
    text-align: center;
    font-weight: bold;
}
