.container-margins{

    margin-left: 60px;
    margin-right: 60px;
}

.container-margins>button{
    float: right;
}

.container-margins .button .cKvPVZ :focus {
    border: none !important;
    outline: none !important;
    outline-offset: 0;
     
}