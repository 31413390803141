.filter-prn-section .cCIpcJ {
    width: 13%;
}

.filter-prn-section .css-yk16xz-control {
    display: none;
}

.select-all-checkbox {
    float: left;
    margin-right: 25px;
}

.filter-gap-filler-1 {
    width: 300px;
    float: left;
}
.filter-gap-filler-common {
    width: 26px;
    float: left;
}

.filter-gap-filler-3 {
    width: 33px;
    float: left;
}

.filter-gap-filler-2 {
    width: 80px;
    float: left;
}

.filter-prn-section .hMSGYU {
    color: #001e50;
    font-family: vw-text, Arial, Helvetica, sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
}
