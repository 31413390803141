.legal-page-title {
    /* font-family: 'vw-head';
    font-size  : 68px;
    margin-left: 10%; */
    font-family: "vw-head";
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 56px;
    text-align: center;
}

.legal-table-col {
    width: 25%;
    border-top: none !important;
    padding-top: 0px;
    border-top: 0px;
    border-bottom: 2px solid rgb(0, 0, 0) !important;
}

.legal-table-row-element {
    border-right: 1px solid rgb(223, 228, 232);
}

.legal-table-col-1 {
    border-right: 2px solid rgb(0, 0, 0);
}

.legal-table-row {
    border-bottom: 1px solid rgb(223, 228, 232);
}

.legal-page {
    margin-left: 15%;
    margin-right: 15%;
}

.legal-table {
    margin-left: 5%;
    margin-right: 5%;
}

.legal-page-headline {
    color: #000000;
    font-family: "vw-head";
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 56px;
}

.legal-page-content {
    color: #000000;
    font-family: "vw-text";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 7%;
}

.legal-page-sub-definition {
    color: #000000;
    font-family: "vw-text";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}

.legal-page-link {
    /* text-decoration: underline; */
    display: inline;
    position: relative;
    color: #001e50;
    text-decoration: none;
    border-bottom: 1px solid currentcolor;
    cursor: pointer;
}

.legal-page a:hover {
    text-decoration: none;
    /* color: currentColor !important; */
}

.dSyoZC:hover,
.dSyoZC:focus {
    border-bottom-color: none;
    color: none;
}

.legal-page-sub-headline {
    color: #000000;
    font-family: "vw-text";
    font-size: 30px;
    margin-left: 6%;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
}
