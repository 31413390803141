.language-edit-version .dropdown_title_style {
    color: #001e50;
    font-family: "vw-text";
    font-weight: bold;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 13px;
    padding: 0;
}

.language-edit-version .gAOfpA {
    width: 120px;
}

.language-edit-version .css-2b097c-container {
    width: 200px;
    margin-left: 20px;
}