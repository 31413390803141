.alert-message {
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}

.alert-message-container {
  border-radius: 4px;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  width: 100%;
}
