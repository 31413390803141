.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.dxWyQJ {
  z-index: 1;
}

.app-header {
  bottom: 0;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.menuitembreadcrumb {
  margin-left: 60px;
}

.app-body-content {
  min-height: 350px;
}

.cCIpcJ {
  width: 100%;
}

.app-footer .bOKhrm {
  padding-right: var(--size-grid001);
  padding-left: var(--size-grid001);
}

.create-prn-link {
  font-size: xx-large;
  padding-left: 100px;
  color: #001e50;
}

html,
body {
  max-width: 100%;
  background: #ffffff;
  font-family: vw-text;
  color: #000000;
}

.gBPoAV {
  overflow: inherit !important;
}

.create-prn-link-section {
  margin-top: 70px;
  text-align: center;
  height: 400px;
}
