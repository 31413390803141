.language-edit-version .dropdown_title_style {
  color: #001e50;
  font-family: "vw-text";
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 13px;
  padding: 0;
}
.language-edit-version .col-md-6 {
  padding-left: 0;
}
.language-edit-version .css-2b097c-container {
  width: 100px;
}

.language-edit-version .css-14jk2my-container {
  width: 100px;
}

.language-edit-version .css-26l3qy-menu {
  margin-top: 15px !important;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}
/* .css-26l3qy-menu{
    margin-top: 4px !important;
} */

.css-1pahdxg-control:hover {
  border-color: none !important;
}
.css-1pahdxg-control {
  box-shadow: none;
}
.css-6q0nyr-Svg {
  color: #001e50;
}

.css-yk16xz-control {
  box-shadow: none;
}

.css-109onse-indicatorSeparator {
  display: none;
}
