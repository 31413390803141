@font-face {
    font-family: "vw-head";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/vwhead-regular.woff2") format("woff2"), url("./fonts/vwhead-regular.woff") format("woff");
}

@font-face {
    font-family: "vw-head";
    font-weight: 400;
    font-style: italic;
    src: url("./fonts/vwhead-regularitalic.woff2") format("woff2"),
        url("./fonts/vwhead-regularitalic.woff") format("woff");
}

@font-face {
    font-family: "vw-head";
    font-weight: 700;
    font-style: normal;
    src: url("./fonts/vwhead-bold.woff2") format("woff2"), url("./fonts/vwhead-bold.woff") format("woff");
}

@font-face {
    font-family: "vw-head";
    font-weight: 700;
    font-style: italic;
    src: url("./fonts/vwhead-bolditalic.woff2") format("woff2"), url("./fonts/vwhead-bolditalic.woff") format("woff");
}

@font-face {
    font-family: "vw-head";
    font-weight: 800;
    font-style: normal;
    src: url("./fonts/vwhead-extrabold.woff2") format("woff2"), url("./fonts/vwhead-extrabold.woff") format("woff");
}

@font-face {
    font-family: "vw-head";
    font-weight: 200;
    font-style: normal;
    src: url("./fonts/vwhead-light.woff2") format("woff2"), url("./fonts/vwhead-light.woff") format("woff");
}

@font-face {
    font-family: "vw-text";
    font-weight: 400;
    font-style: normal;
    src: url("./fonts/vwtext-regular.woff2") format("woff2"), url("./fonts/vwtext-regular.woff") format("woff");
}

@font-face {
    font-family: "vw-text";
    font-weight: 400;
    font-style: italic;
    src: url("./fonts/vwtext-regularitalic.woff2") format("woff2"),
        url("./fonts/vwtext-regularitalic.woff") format("woff");
}

@font-face {
    font-family: "vw-text";
    font-weight: 700;
    font-style: normal;
    src: url("./fonts/vwtext-bold.woff2") format("woff2"), url("./fonts/vwtext-bold.woff") format("woff");
}

@font-face {
    font-family: "vw-text";
    font-weight: 700;
    font-style: italic;
    src: url("./fonts/vwtext-bolditalic.woff2") format("woff2"), url("./fonts/vwtext-bolditalic.woff") format("woff");
}

@font-face {
    font-family: "vw-text";
    font-weight: 200;
    font-style: normal;
    src: url("./fonts/vwtext-light.woff2") format("woff2"), url("./fonts/vwtext-light.woff") format("woff");
}
