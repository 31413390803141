.selected-page-button{
    width: 37px;
    height: 37px;
    background-color: #092f82;
    color: white;
    padding-top: 5px;
    cursor: default;
}

.not-selected-page-button{
    width: 37px;
    height: 37px;
    background-color: #f3f3f3;
    padding-top: 5px;
    cursor: pointer;
}
