/* .css-26l3qy-menu{
  margin-top: -10px !important;
} */

.dropdown_title_style {
  height: 16px;
  color: #001e50;
  font-family: "vw-text";
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 20px;
  float: left;
}

.select_option_filter > div {
  border-radius: 0;
  outline: none;
  border-color: #8c9094;
  border: none;
  border-bottom: 1px solid #8c9094;
  line-height: 23px;
  position: relative;
  /* float: left;s */
}

.select_option_filter .css-6q0nyr-Svg {
  color: #001e50;
}

.dropdown_placeholder_entry_style {
  height: 16px;
  width: 55%;
  color: #001e50;
  font-family: "vw-text";
  letter-spacing: 0;
  line-height: 16px;
}

.subtextlabeldropdown {
  color: #001e50;
  font-family: "vw-text";
  letter-spacing: 0;
  line-height: 16px;
}

.extra_margin {
  margin-top: 20px;
}

.multiselect-accordion {
  height: 16px;
  width: 70px;
  color: #001e50;
  font-family: "vw-text";
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 45px;
  margin-top: 25px;
}

.margin_user_dropdown {
  margin-left: 120px;
  margin-top: 20px;
}
