.footer{
    background-color: #2f3538;
    position: fixed;
    bottom: 0;
    margin: 0;
    width: 100%;
    z-index: 1;
    height: 100px;
}

.terms-and-conditions{
    color: white;
}

.href{
    color: #00B0F0;
}

.href:hover{
    color: #00B0F0;
    text-decoration: underline;
}

.accept-button{
    display: inline-flex;
    text-align: center;
    background: #ffffff;
    align-items: center;
    min-height: 44px;
    margin: 0;
    padding: 0 40px;
    border: 2px solid;
    border-radius: 22px;
    outline: none;
    color: #000000;
}

.accept-button:hover{
    background: #f3f3f3;
}
